<template>
  <div id="scheduled-transfers">
    <div id="tour-scheduled-transfers-list">
      <div class="d-flex justify-content-between">
        <back-with-title
          :title="`${userType === 'users' ? $t('Your') : `${company.name}${$t('\'s')}`} ${$t('Scheduled Transfers')}`"
          route-name="wallet"
          :params="userType === 'company' ? { tab: 'Company Accounts' } : {}"
          tour-keyword="tour-scheduled-back-btn"
        />

        <div class="d-flex align-items-baseline">
          <b-button
            v-b-modal.create-transfer-schedule-modal
            variant="primary"
            class="mr-2 mb-2"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('Add New') }}
          </b-button>
          <custom-switch
            v-model="includeDeactivated"
            :right-text="$t('Include Deactivated Schedules')"
            :initial-value="includeDeactivated"
            @input="getScheduledTransfers"
          />
        </div>
      </div>

      <!-- Table View -->
      <b-overlay :show="isLoading">
        <b-table
          id="schedule-table"
          class="schedule-table pb-3"
          :items="scheduledTransfers"
          :fields="fields"
          responsive
          show-empty
          striped
          bordered
        >
          <template #head()="row">
            <span class="text-capitalize">{{ $t(row.label) }}</span>
          </template>
          <!-- Title -->
          <template #cell(title)="row">
            <div>
              <div class="d-flex">
                <h5>{{ row.item.name }}</h5>
                <b-badge
                  v-if="row.item.deactivatedAt"
                  class="ml-1"
                  variant="danger"
                >
                  {{ $t('Deactivated') }}
                </b-badge>
              </div>

              <p
                class="description cursor-pointer"
                :class="({ 'ellipsis-para': showFullDescription !== row.index + 1 })"
                @click="() => { showFullDescription = showFullDescription === row.index+1 ? 0 : row.index+1 }"
              >
                {{ row.item.description }}
              </p>
              <span class="options">
                <b-link
                  class="cursor-pointer option-left"
                  @click="showModal(row.item, 'create-transfer-schedule-modal')"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                  {{ $t('Edit') }}
                </b-link>
                |
                <b-link
                  class="cursor-pointer option-right"
                  :class="row.item.deactivatedAt ? 'text-success' : 'text-danger'"
                  @click="showModal(row.item, 'change-schedule-status-modal')"
                >
                  <i class="fa fa-clock" />
                  {{ row.item.deactivatedAt ? $t('Activate') : $t('Deactivate') }}
                </b-link>
              </span>
            </div>
          </template>
          <!-- Transfer From -->
          <template #cell(transferFrom)="row">
            <div class="d-flex">
              <b-avatar
                size="32"
                class="mr-1"
              >
                <i
                  class="f-16"
                  :class="getWalletIcon(row.item.source.wallet.__typename)"
                />
              </b-avatar>
              <div>
                <h5 class="mb-0">
                  {{ row.item.source.description }}
                </h5>
                <p class="small mb-0">
                  Family: {{ row.item.source.family.description }}
                </p>
              </div>
            </div>
          </template>
          <!-- Transfer To -->
          <template #cell(transferTo)="row">
            <div class="d-flex">
              <b-avatar
                size="32"
                class="mr-1"
              >
                <i
                  class="f-16"
                  :class="getWalletIcon(row.item.destination.wallet ? row.item.destination.wallet.__typename : 'family')"
                />
              </b-avatar>
              <div>
                <h5 class="mb-0">
                  {{ row.item.destination.description }}
                </h5>
                <p class="small mb-0">
                  {{ row.item.destination.family ? `Family: ${row.item.destination.family.description}` : 'Entire Family' }}
                </p>
              </div>
            </div>
          </template>
          <!-- Amount -->
          <template #cell(amount)="row">
            <span class="text-capitalize">{{ row.item.transferType.toLowerCase() }}:</span>
            <span>
              {{ row.item.transferType === 'PERCENT' ? `${row.item.transferAmount}%` : `${company.currency}${row.item.transferAmount}` }}
            </span>
          </template>
          <!-- Schedule -->
          <template #cell(schedule)="row">
            <span class="text-capitalize">Every {{ paymentConfiguration(row.item.schedule).toLowerCase() }}</span>
          </template>

          <!-- Empty Table -->
          <template #empty>
            <div class="w-25 mx-auto">
              <lottie-animation
                :animation-data="require('@/assets/animations/Transfer.json')"
                :loop="true"
                :auto-play="true"
              />
            </div>
            <p class="text-center my-2">
              If you have transfers to be done regulary,
              <b-link
                v-b-modal.create-transfer-schedule-modal
                class="text-decoration-underline"
              >
                <u>create a scheduled transfer</u>
              </b-link>
              and let Workzone do it for you.
            </p>
          </template>
        </b-table>

        <!-- Pagination -->
        <b-pagination
          v-if="totalRows > perPage"
          v-model="currentPage"
          class="pb-2 float-right"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="schedule-table"
          @input="getScheduledTransfers"
        />
      </b-overlay>
    </div>

    <b-modal
      id="create-transfer-schedule-modal"
      :title="$t('Create New Transfer Schedule')"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @hidden="() => { selectedSchedule = null }"
    >
      <create-transfer-schedule
        :selected-schedule="selectedSchedule"
        :mode="selectedSchedule ? 'update' : 'create'"
        :user-type="userType"
        @discard="() => { selectedSchedule = null; $bvModal.hide('create-transfer-schedule-modal') }"
        @refetch="onScheduleUpdated"
      />
    </b-modal>

    <!-- Deactivate Transfer Confirm Modal -->
    <confirm-modal
      v-if="selectedSchedule"
      modal="change-schedule-status-modal"
      :title="selectedSchedule.details.deactivatedAt ? $t('Activate Scheduled Transfer') : $t('Deactivate Scheduled Transfer')"
      :alert="`${selectedSchedule.details.deactivatedAt ? $t('messages.activate-transfer-confirmation') : $t('messages.deactivate-transfer-confirmation')}  - ${selectedSchedule.details.name}?`"
      :ok-variant="selectedSchedule.details.deactivatedAt ? 'success' : 'danger'"
      alert-variant="none"
      :icon-color="selectedSchedule.details.deactivatedAt ? 'success' : 'danger'"
      :ok-title="selectedSchedule.details.deactivatedAt ? $t('Activate') : $t('Deactivate')"
      :cancel-title="$t('Cancel')"
      :icon="selectedSchedule.details.deactivatedAt ? 'InfoIcon' : 'AlertTriangleIcon'"
      icon-size="50"
      @confirm="changeScheduleStatus"
    />
  </div>
</template>

<script>
import {
  BTable, BAvatar, BButton, BModal, BOverlay, BBadge, BLink, BPagination,
} from 'bootstrap-vue'
import BackWithTitle from '@/views/common/components/BackWithTitle.vue'
import {
  getCurrentInstance,
  onMounted, reactive, toRefs,
} from 'vue'
import CustomSwitch from '@/views/common/components/CustomSwitch.vue'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import { setComponentReady } from '@/views/tour/tour'
import LottieAnimation from 'lottie-web-vue'
import CreateTransferSchedule from './TransferSchdules.vue/CreateTransferSchedule.vue'
import ConfirmModal from './reusables/ConfirmModal.vue'

export default {
  components: {
    BTable,
    BAvatar,
    BButton,
    BModal,
    BOverlay,
    BBadge,
    BLink,
    BPagination,
    CustomSwitch,
    BackWithTitle,
    CreateTransferSchedule,
    ConfirmModal,
    LottieAnimation,
  },
  setup() {
    const root = getCurrentInstance().proxy.$root
    const route = root.$route
    const store = root.$store

    const { getWalletIcon } = useWallet()

    const state = reactive({
      company: store.state.project.company,
      userType: route.params.userType,
      includeDeactivated: false,
      isLoading: false,
      fields: [
        { key: 'title', thClass: 'w-25' },
        'transferFrom', 'transferTo', 'amount', 'schedule'],
      scheduledTransfers: [],
      selectedSchedule: null,
      showFullDescription: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
    })

    const getScheduledTransfers = () => {
      state.isLoading = true
      let api = 'getScheduledTransfers'
      let res = 'me'
      const params = {
        includeInactive: state.includeDeactivated,
        page: state.currentPage,
        first: state.perPage,
      }

      if (state.userType === 'company') {
        api = 'getCompanyScheduledTransfers'
        res = 'company'
        params.companyUid = state.company.uuid
      }

      useApollo[state.userType][api](params).then(response => {
        const data = response.data[res].scheduledTransfers
        state.scheduledTransfers = data.data
        state.totalRows = data.total
      }).finally(() => {
        state.isLoading = false
        setComponentReady()
      })
    }

    onMounted(getScheduledTransfers)

    const paymentConfiguration = schedule => {
      switch (schedule.__typename) {
        case 'CompanyPaymentOnWeek':
          return `${schedule.weekNum} ${schedule.weekDay} of the month`
        case 'CompanyPaymentOnMonth':
          return schedule.firstDay ? 'first day of the month' : 'last day of the month'
        case 'CompanyPaymentOnDays':
          return `${schedule.numberDays} days`
        case 'CompanyPaymentOnDate':
          return `${root.ORDINAL_NUMBER(schedule.day)} day of the month`
        default:
          return null
      }
    }

    const getScheduleType = type => {
      switch (type) {
        case 'CompanyPaymentOnMonth':
          return 'MONTHLY'
        case 'CompanyPaymentOnWeek':
          return 'WEEK'
        case 'CompanyPaymentOnDate':
          return 'DATE'
        default:
          return 'DAYS'
      }
    }

    const showModal = ({
      source, destination, schedule, ...data
    }, modal) => {
      state.selectedSchedule = {
        sourceShare: {
          uuid: source.uuid,
          label: source.description,
          balance: source.balance,
          type: source.wallet.__typename,
        },
        sourceWallet: {
          uuid: source.family.uuid,
          label: source.family.description,
          type: source.wallet.__typename,
        },
        destinationShare: {
          ...(destination.__typename === 'WalletShare' ? {
            uuid: destination.uuid,
            label: destination.description,
            type: destination.wallet?.__typename,
          } : []),
        },
        destinationWallet: {
          uuid: destination.family?.uuid ?? destination.uuid,
          label: destination.family?.description ?? destination.description,
        },
        transferSetting: {
          transferType: data.transferType,
          transferAmount: data.transferAmount,
        },
        details: {
          uuid: data.uuid,
          name: data.name,
          description: data.description,
          deactivatedAt: data.deactivatedAt,
        },
        schedule: {
          type: getScheduleType(schedule.__typename),
          configuration: schedule,
        },
      }
      root.$nextTick(() => {
        root.$bvModal.show(modal)
      })
    }

    const onScheduleUpdated = () => {
      state.selectedSchedule = null
      root.$bvModal.hide('create-transfer-schedule-modal')
      getScheduledTransfers()
    }

    const changeScheduleStatus = () => {
      state.isLoading = true
      const params = {
        scheduleUid: state.selectedSchedule.details.uuid,
        activate: !!state.selectedSchedule.details.deactivatedAt,
      }
      let api = 'activateWalletTransferSchedule'
      if (state.userType === 'company') {
        api = 'activateCompanyWalletTransferSchedule'
        params.companyUid = state.company.uuid
      }

      useApollo[state.userType][api](params).then(response => {
        root.showSuccessMessage({
          data: {
            message: response.data.activateWalletTransferSchedule.message,
          },
        })
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        state.selectedSchedule = null
        getScheduledTransfers()
      })
    }

    return {
      ...toRefs(state),
      paymentConfiguration,
      getScheduledTransfers,
      showModal,
      onScheduleUpdated,
      changeScheduleStatus,

      getWalletIcon,
    }
  },
}
</script>

<style lang="scss">
#scheduled-transfers {
  .options {
    opacity: 0;
  }
  .schedule-table tbody tr:hover .options {
    opacity: 1;
  }
  .option-left {
    margin-right: 8px;
  }
  .option-right {
    margin-left: 8px;
  }
  #schedule-table .badge {
    max-height: 21px;
  }
}
</style>
