<template>
  <b-overlay
    :show="isProcessing"
    class="schedule-transfer"
  >
    <b-card>
      <validation-observer ref="scheduleTransferForm">
        <stepper
          :step="activeStep"
          :completed="currentStep"
          :tabs="scheduleCreationSteps"
          @jump-to="step => goToStep(step)"
        >
          <!-- Source -->
          <template #1>
            <select-source-destination
              type="source"
              :wallet-family="walletFamilyForSource"
              :selected-wallet="form.source"
              :current-data="currentSource"
              @wallet-selected="(type, walletData) => setWalletData(type, walletData)"
            />
          </template>
          <!-- Destination -->
          <template #2>
            <select-source-destination
              type="destination"
              :wallet-family="walletFamilyForDestination"
              :selected-wallet="form.destination"
              :current-data="currentDestination"
              @wallet-selected="(type, walletData) => setWalletData(type, walletData)"
              @change-destination-type="val => onDestinationTypeChange(val)"
            />
          </template>
          <!-- Schedule -->
          <template #3>
            <h2 class="mb-2">
              {{ $t('Set Schedule') }}
            </h2>
            <pay-day
              :fetch-company-setting="false"
              :current-data="form.schedule"
              :mode="mode"
              :no-day-increment="true"
              @updateInput="schedule => { setSchedule(schedule); scheduleError = '' }"
            />
            <p class="small text-danger mb-3">
              {{ scheduleError }}
            </p>
          </template>
          <!-- Amount -->
          <template #4>
            <amount
              :current-data="form.input"
              @amount-setting="(type, data) => setFormData('input', type, data)"
            />
          </template>
          <!-- Finish -->
          <template #5>
            <finish
              :preview-data="getScheduleDataForPreview"
              :current-data="form.remarks"
              @remarks="(type, value) => setFormData('remarks', type, value)"
            />
          </template>
        </stepper>
      </validation-observer>

      <div class="d-flex mt-1 justify-content-between px-50">
        <b-button
          variant="danger"
          @click="() => { resetForm(); $emit('discard') }"
        >
          {{ $t('Discard') }}
        </b-button>
        <div>
          <b-button
            v-if="activeStep !== 1"
            variant="secondary"
            @click="back"
          >
            {{ $t('Back') }}
          </b-button>

          <b-button
            class="mx-1"
            variant="primary"
            @click="next"
          >
            {{ activeStep === 5 ? $t('Finish') : $t('Next') }}
          </b-button>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BButton, BOverlay,
} from 'bootstrap-vue'
import PayDay from '@/views/Settings/company/main/PayDay.vue'
import {
  onMounted, watch, computed, reactive, toRefs,
  getCurrentInstance,
  ref,
} from 'vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationObserver, extend } from 'vee-validate'
import Stepper from '@/views/common/components/Stepper.vue'
import InputFormatter from '@/views/Settings/company/main/inputFormatter'
import SelectSourceDestination from './components/SelectSourceDestination.vue'
import Amount from './components/Amount.vue'
import Finish from './components/Finish.vue'

extend('percent', value => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) return 'Transfer percent should be a number'
  if (value >= 0 && value <= 100) return true
  return 'Transfer percent should be between 0 and 100'
})

export default {
  components: {
    BCard,
    BOverlay,
    BButton,
    ValidationObserver,
    Stepper,
    PayDay,
    SelectSourceDestination,
    Amount,
    Finish,
  },
  props: {
    selectedSchedule: {
      type: Object,
      default: () => null,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
    mode: {
      type: String,
      default: () => 'create',
    },
  },
  watch: {
    selectedSchedule: {
      handler(val) {
        if (val) {
          this.setForm(val)
          this.currentStep = this.scheduleCreationSteps.length
          if (!val.destinationShare.uuid) this.isEntireFamily = true
          else this.isEntireFamily = false
        }
      },
      immediate: true,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const store = root.$store
    const scheduleTransferForm = ref(null)

    const state = reactive({
      form: {
        source: {},
        destination: {},
        input: {
          transferType: 'PERCENT',
          transferAmount: 0,
        },
        schedule: {},
        remarks: {},
      },
      scheduleError: '',
      scheduleCreationSteps:
      [
        {
          id: 1,
          title: root.$t('Source'),
          success: true,
          error: '',
        },
        {
          id: 2,
          title: root.$t('Destination'),
          success: true,
          error: '',
        },
        {
          id: 3,
          title: root.$t('Schedule'),
          success: true,
          error: '',
        },
        {
          id: 4,
          title: root.$t('Amount'),
          success: true,
          error: '',
        },
        {
          id: 5,
          title: root.$t('Finish'),
          success: true,
          error: '',
        },
      ],
      currentSource: {},
      currentDestination: {
        isEntireFamily: false,
      },
      walletFamily: [
        { label: 'Select Account Family', uuid: null },
      ],
      currentStep: 0,
      activeStep: 1,
      isProcessing: false,
    })

    // get user / company wallets
    const getWallet = () => {
      let api = 'getUserWallet'
      let params = {}
      let res = 'me'
      if (props.userType === 'company') {
        api = 'getCompanyWallet'
        params = { company: store.state.project.selectedCompany }
        res = 'company'
      }

      state.isProcessing = true
      useApollo[props.userType][api](params).then(response => {
        state.walletFamily = response.data[res].wallets.data.map(family => ({
          id: family.uuid,
          label: family.description,
          icon: '',
          balance: family.balance,
          children: family.shares.map(share => ({
            id: share.uuid,
            label: share.description,
            icon: share.wallet.__typename === 'InternalWallet' ? 'fa fa-money-bill-1' : 'fa fa-university',
            iconType: 'font-awesome',
            type: share.wallet.__typename,
            balance: share.balance,
            shareDirection: share.shareDirection,
          })),
        }))
      }).finally(() => {
        state.isProcessing = false
        root.$emit('no-lazy')
      })
    }

    // Computed Properties
    const getScheduleDataForPreview = computed(() => ({
      sourceShare: state.currentSource.share,
      sourceWallet: state.currentSource.wallet,
      destinationShare: state.currentDestination.share,
      destinationWallet: state.currentDestination.wallet,
      transferSetting: state.form.input,
      schedule: state.form.schedule,
    }))

    const walletFamilyForSource = computed(() => {
      const families = state.walletFamily.map(family => ({
        ...family,
        children: family.children?.filter(child => child.shareDirection !== 'INBOUND'),
      }))
      return families.filter(family => family.children?.length)
    })

    const walletFamilyForDestination = computed(() => {
      if (state.currentDestination.isEntireFamily) return state.walletFamily.filter(family => family.id !== state.form.source.walletUid && family.children[0].shareDirection !== 'OUTBOUND')
      const destinationWallets = state.walletFamily.map(family => ({
        ...family,
        children: family.children?.filter(child => child.id !== state.form.source.shareUid && child.shareDirection !== 'OUTBOUND'),
      }))
      return destinationWallets.filter(wallet => wallet.children.length)
    })

    // Watcher
    watch(state.form.source, val => {
      if (val.walletUid && state.form.destination.walletUid && val.walletUid === state.form.destination.walletUid) {
        state.form.destination.walletUid = null
        state.form.destination.shareUid = null
      }
    })

    onMounted(() => getWallet())

    // Methods
    // set schedule which is emitted by pay day
    const setSchedule = ({ type, value }) => {
      state.form.schedule.type = type
      state.form.schedule.configuration = value
      state.scheduleCreationSteps.find(step => step.title === 'Schedule').success = true
    }

    const setFormData = (field, key, value) => {
      state.form[field][key] = value
    }

    const validateSchedule = () => {
      if (!state.form.schedule.type || !state.form.schedule.configuration) {
        state.scheduleError = 'The settlement type field is required'
      } else if (state.form.schedule.type === 'DAYS' && typeof state.form.schedule.configuration === 'string' && state.form.schedule.configuration.split('_')[1] === 'undefined') {
        state.scheduleError = 'The settlement date is required'
      } else return true
      return false
    }

    const goToStep = step => {
      const currentStep = state.scheduleCreationSteps[state.activeStep - 1]
      scheduleTransferForm.value.validate().then(success => {
        if (!success) {
          currentStep.success = false
          currentStep.error = `${currentStep.title} is required`
        } else {
          currentStep.success = true
          currentStep.error = ''
        }
      })
      if (state.activeStep === 3) {
        if (!validateSchedule()) currentStep.success = false
      }
      if (step === 5) {
        const invalid = state.scheduleCreationSteps.find(st => !st.success)
        if (invalid) {
          state.activeStep = invalid.id
          root.$emit('bv::show::popover', `${invalid.title}-error`)
        } else state.activeStep = step
      } else state.activeStep = step
    }

    const back = () => {
      if (state.activeStep === 3) {
        if (validateSchedule()) state.activeStep -= 1
        return
      }
      scheduleTransferForm.value.validate().then(success => {
        if (success) {
          state.activeStep -= 1
        } else {
          const currentStep = state.scheduleCreationSteps[state.activeStep - 1]
          currentStep.success = false
          state.activeStep -= 1
        }
      })
    }

    // reset form
    const resetForm = () => {
      state.form = {
        source: {},
        destination: {},
        input: {
          transferType: 'PERCENT',
          transferAmount: 0,
        },
        schedule: {},
        remarks: {},
      }
      state.activeStep = 1
      state.currentStep = 0
      state.currentSource = {}
      state.currentDestination = {}
    }

    // create scheduled transfer
    const createTransferSchedule = () => {
      state.isProcessing = true
      state.form.input.transferAmount = Number(state.form.input.transferAmount)

      let api = 'createWalletTransferSchedule'
      const params = { ...state.form }

      if (props.userType === 'company') {
        api = 'createCompanyWalletTransferSchedule'
        params.companyUid = store.state.project.selectedCompany
      }

      useApollo[props.userType][api](params).then(response => {
        root.showSuccessMessage({
          data: {
            message: response.data.createWalletTransferSchedule.message,
          },
        })
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        state.isProcessing = false
        resetForm()
        root.$emit('refetch')
      })
    }

    const updateTransferSchedule = () => {
      state.isProcessing = true
      state.form.input.transferAmount = Number(state.form.input.transferAmount)
      const { source, ...data } = state.form

      let api = 'updateWalletTransferSchedule'
      const params = {
        ...data,
        scheduleUid: props.selectedSchedule.details.uuid,
      }

      if (props.userType === 'company') {
        api = 'updateCompanyWalletTransferSchedule'
        params.companyUid = store.state.project.selectedCompany
      }

      if (typeof params.schedule.configuration !== 'string') {
        params.schedule.configuration = InputFormatter(params.schedule.type, params.schedule.configuration)
      }

      useApollo[props.userType][api](params).then(response => {
        root.showSuccessMessage({
          data: {
            message: response.data.updateWalletTransferSchedule.message,
          },
        })
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        state.isProcessing = false
        resetForm()
        root.$emit('refetch')
      })
    }

    const next = () => {
      scheduleTransferForm.value.validate().then(success => {
        if (success) {
          switch (state.activeStep) {
            case 2:
              state.scheduleCreationSteps.find(step => step.title === 'Destination').success = true
              if (state.activeStep === state.currentStep + 1) state.currentStep += 1
              state.activeStep += 1
              break
            case 3:
              if (validateSchedule()) {
                if (state.activeStep === state.currentStep + 1) state.currentStep += 1
                state.activeStep += 1
              }
              break
            case 4:
              state.scheduleCreationSteps[3].success = true
              state.scheduleCreationSteps[3].error = ''
              const invalid = state.scheduleCreationSteps.find(step => !step.success)
              if (invalid) {
                state.activeStep = invalid.id
                root.$emit('bv::show::popover', `${invalid.title}-error`)
                break
              } else if (state.activeStep === state.currentStep + 1) {
                state.currentStep += 1
              }

              state.activeStep += 1
              break
            case 5:
              if (props.selectedSchedule) updateTransferSchedule()
              else createTransferSchedule()
              break
            default:
              if (state.activeStep === state.currentStep + 1) state.currentStep += 1
              state.activeStep += 1
          }
        }
      })
    }

    const setForm = data => {
      state.form = {
        source: {
          walletUid: data.sourceWallet.uuid,
          shareUid: data.sourceShare.uuid,
        },
        destination: {
          walletUid: data.destinationWallet.uuid,
          shareUid: data.destinationShare.uuid ?? null,
        },
        input: data.transferSetting,
        remarks: {
          name: data.details.name,
          description: data.details.description,
        },
        schedule: data.schedule,
      }
      state.currentSource = {
        wallet: data.sourceWallet,
        share: data.sourceShare,
      }
      state.currentDestination = {
        isEntireFamily: !data.destinationShare.uuid,
        wallet: data.destinationWallet,
        share: data.destinationShare,
      }
    }

    const setWalletData = (type, data) => {
      const destination = state.scheduleCreationSteps.find(step => step.title === 'Destination')
      if (type === 'source') {
        state.form.source = {
          walletUid: data.walletUid,
          shareUid: data.shareUid,
        }
        state.currentSource = {
          wallet: data.currentWallet,
          share: data.currentShare,
        }
        if (state.form.source.shareUid === state.form.destination.shareUid) {
          destination.success = false
          destination.error = 'Destination account cannot be the same as source account'
          state.form.destination = {
            walletUid: null,
            shareUid: null,
          }
          state.currentDestination = {
            isEntireFamily: false,
            wallet: null,
            share: null,
          }
          setTimeout(() => {
            root.$emit('bv::show::popover', `${destination.title}-error`)
          }, 100)
        }
        state.scheduleCreationSteps.find(step => step.title === 'Source').success = true
      } else {
        state.form.destination = {
          walletUid: data.walletUid,
          shareUid: data.shareUid,
        }
        state.currentDestination = {
          isEntireFamily: data.isEntireFamily,
          wallet: data.currentWallet,
          share: data.currentShare,
        }
        state.scheduleCreationSteps.find(step => step.title === 'Destination').success = true
        destination.error = ''
      }
    }

    const onDestinationTypeChange = val => {
      state.currentDestination = {
        isEntireFamily: val,
      }
      state.form.destination = {}
    }

    return {
      ...toRefs(state),

      getScheduleDataForPreview,
      walletFamilyForSource,
      walletFamilyForDestination,

      scheduleTransferForm,
      setFormData,
      setWalletData,
      goToStep,
      setForm,
      resetForm,
      setSchedule,
      onDestinationTypeChange,
      next,
      back,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/wallet-page.scss";
@import "@core/scss/base/components/treeselect.scss";
</style>
