<template>
  <div>
    <!-- Header -->
    <div class="d-flex justify-content-between">
      <div v-if="scheduleData.details">
        <div class="d-flex">
          <h3 class="mb-0">
            {{ scheduleData.details.name }}
          </h3>
          <b-badge
            v-if="scheduleData.details.deactivatedAt"
            class="ml-1"
            variant="danger"
          >
            Deactivated
          </b-badge>
        </div>

        <p class="mb-0">
          {{ scheduleData.details.description }}
        </p>
      </div>

      <div v-if="!preview">
        <b-button
          v-b-tooltip.hover="'Update Schedule'"
          class="edit-btn ml-1"
          @click="$emit('update')"
        >
          <feather-icon icon="Edit2Icon" />
        </b-button>

        <b-button
          v-b-tooltip.hover
          :title="scheduleData.details.deactivatedAt ? 'Activate Schedule' : 'Deactivate Schedule'"
          class="edit-btn ml-1"
          :variant="scheduleData.details.deactivatedAt ? 'success' : 'danger'"
          @click="() => $bvModal.show(`change-schedule-status-${scheduleData.details.uuid}-modal`)"
        >
          <feather-icon :icon="scheduleData.details.deactivatedAt ? 'ClockIcon' : 'Trash2Icon'" />
        </b-button>
        <hr>
      </div>
    </div>

    <!-- Main Content -->
    <div class="d-flex justify-content-between">
      <!-- Source -->
      <div>
        <h5>{{ scheduleData.sourceShare?.label }}</h5>
        <div
          class="d-flex"
          :class="preview ? 'ml-2' : ''"
        >
          <b-avatar
            :size="preview ? 48 : 38"
            class="mr-1"
          >
            <i
              :class="getWalletIcon(scheduleData.sourceShare.type)"
              :style="`font-size: ${preview ? '25px' : '16px'}`"
            />
          </b-avatar>
          <div>
            <p class="mb-0">
              Balance: {{ formatAmount(scheduleData.sourceShare.balance) }}
            </p>
            <p class="mb-0">
              Family: {{ scheduleData.sourceWallet?.label }}
            </p>
          </div>
        </div>
      </div>

      <i class="fa fa-arrow-right mx-2 mb-1 mt-2 f-25" />

      <!-- Destination -->
      <div>
        <h5>
          {{ scheduleData.destinationShare && scheduleData.destinationShare.id ? scheduleData.destinationShare.label : 'Entire Family' }}
        </h5>
        <div
          class="d-flex align-items-center"
          :class="preview ? 'ml-2' : ''"
        >
          <b-avatar
            :size="preview ? 48 : 38"
            class="mr-1"
          >
            <i
              :class="getWalletIcon(scheduleData.destinationShare.type)"
              :style="`font-size: ${preview ? '25px' : '16px'}`"
            />
          </b-avatar>
          <div>
            <p class="mb-0">
              Family: {{ scheduleData.destinationWallet?.label }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <b-alert
      :show="true"
      class="p-1 mt-2 small d-flex align-items-center"
      variant="none"
    >
      <feather-icon
        icon="InfoIcon"
        size="32"
        class="mr-1"
      />
      <p>
        Every {{ paymentConfiguration.toLowerCase() }},
        {{ scheduleData.transferSetting.transferType === 'PERCENT' ? `${scheduleData.transferSetting.transferAmount}% of total balance of` : `${currency}${scheduleData.transferSetting.transferAmount} from` }}
        {{ scheduleData.sourceShare?.label }} will be transferred to
        {{ scheduleData.destinationShare?.id ? scheduleData.destinationShare?.label : `the entire family - ${scheduleData.destinationWallet?.label}` }}.
      </p>
    </b-alert>

    <!-- Deactivate Transfer Confirm Modal -->
    <confirm-modal
      v-if="!preview && scheduleData"
      :modal="`change-schedule-status-${scheduleData.details.uuid}-modal`"
      :title="scheduleData.details.deactivatedAt ? 'Activate Scheduled Transfer' : 'Deactivate Scheduled Transfer'"
      :description="`Are you sure you want to ${scheduleData.details.deactivatedAt ? 'activate' : 'deactivate'} the scheduled transfer - ${scheduleData.details.name}?`"
      :ok-variant="scheduleData.details.deactivatedAt ? 'primary' : 'danger'"
      :ok-title="scheduleData.details.deactivatedAt ? 'Activate' : 'Deactivate'"
      @confirm="changeScheduleStatus"
    />
  </div>
</template>

<script>
import {
  BAvatar, BAlert, BButton, BBadge,
} from 'bootstrap-vue'
import { computed, getCurrentInstance, ref } from 'vue'
import useApollo from '@/plugins/graphql/useApollo'
import useWallet from '@/utils/wallet'
import ConfirmModal from '../../reusables/ConfirmModal.vue'

export default {
  components: {
    BAvatar,
    BAlert,
    BButton,
    BBadge,
    ConfirmModal,
  },
  props: {
    scheduleData: {
      type: Object,
      required: true,
    },
    preview: {
      type: Boolean,
      default: () => false,
    },
    userType: {
      type: String,
      default: () => 'users',
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const store = root.$store

    const company = store.state.project.selectedCompany
    const currency = ref(store.state.project.company.currency)
    const { getWalletIcon } = useWallet()

    // Computed Properties
    const paymentConfiguration = computed(() => {
      const conf = props.scheduleData.schedule.configuration
      switch (props.scheduleData.schedule.type) {
        case 'WEEK':
          if (typeof conf === 'object') return `${conf.weekNum} ${conf.weekDay} OF THE MONTH`
          return `${(props.preview ? `${conf.split('_').join(' ')}` : `${conf.weekNum} ${conf.weekDay}`)} OF THE MONTH`
        case 'MONTHLY':
          return conf === 'FIRST_OF_MONTH' ? 'FIRST DAY OF THE MONTH' : 'LAST DAY OF THE MONTH'
        case 'DAYS':
          if (typeof conf === 'object') return `${conf.numberDays} DAYS`
          return `${conf.split('_')[0]} DAYS`
        case 'DATE':
          if (typeof conf === 'object') return `${root.ORDINAL_NUMBER(conf.day)} DAY OF THE MONTH`
          return `${root.ORDINAL_NUMBER(conf.split('_')[0])} DAY OF THE MONTH`
        default:
          return null
      }
    })

    // Methods
    const changeScheduleStatus = () => {
      const params = {
        scheduleUid: props.scheduleData.details.uuid,
        /* eslint-disable no-unneeded-ternary */
        activate: props.scheduleData.details.deactivatedAt ? true : false,
      }
      let api = 'activateWalletTransferSchedule'
      if (props.userType === 'company') {
        api = 'activateCompanyWalletTransferSchedule'
        params.companyUid = company
      }

      useApollo[props.userType][api](params).then(response => {
        root.showSuccessMessage({
          data: {
            message: response.data.activateWalletTransferSchedule.message,
          },
        })
      }).catch(error => {
        root.showErrorMessage(error)
      }).finally(() => {
        root.$emit('schedule-status-changed')
      })
    }

    return {
      currency,
      paymentConfiguration,
      changeScheduleStatus,
      getWalletIcon,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/pages/wallet-page.scss";
</style>
